import './TeamRankingGrid.css';
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import teamColors from '../assets/teamColors.json';
import teamInfo from '../assets/teamDetails.json';  // Import your teams.json file
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import { Link } from 'react-router-dom';

const TeamRankingsGrid = () => {
  const [rankings, setRankings] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [showGrid, setShowGrid] = useState(true);
  const [conferenceFilter, setConferenceFilter] = useState('All');
  const [divisionFilter, setDivisionFilter] = useState('All');

  const latestCsvFilePath = '/PowerRatings_Latest.csv';
  const weeklyCsvFilePath = '/PowerRatings_Weekly.csv';

  // Fetch the latest rankings
  useEffect(() => {
    fetch(latestCsvFilePath)
      .then(response => response.text())
      .then(data => {
        Papa.parse(data, {
          header: true,
          dynamicTyping: true,
          complete: (results) => {
            const validRows = results.data.filter(row => row.Team && row['Power Rating']);
            setRankings(validRows);
          }
        });
      })
      .catch(error => console.error('Error fetching CSV:', error));
  }, [latestCsvFilePath]);

  // Fetch the weekly power ratings
  useEffect(() => {
    fetch(weeklyCsvFilePath)
      .then(response => response.text())
      .then(data => {
        Papa.parse(data, {
          header: true,
          dynamicTyping: true,
          complete: (results) => {
            const validRows = results.data.filter(row => row.Team && row['Power Rating'] && row['week']);
            setWeeklyData(validRows);
          }
        });
      })
      .catch(error => console.error('Error fetching weekly CSV:', error));
  }, [weeklyCsvFilePath]);

  // Handle toggle between grid and timeline
  const handleToggle = () => {
    setShowGrid(!showGrid);
  };

  // Filter rankings based on conference and division
  const filteredRankings = rankings.filter(team => {
    const teamInfoEntry = teamInfo.teams[team.Team];
    const conferenceMatch = conferenceFilter === 'All' || teamInfoEntry?.conference === conferenceFilter;
    const divisionMatch = divisionFilter === 'All' || teamInfoEntry?.division === divisionFilter;
    return conferenceMatch && divisionMatch;
  });

  // Filter weekly data for the chart based on conference and division
  const filteredWeeklyData = weeklyData.filter(row => {
    const teamInfoEntry = teamInfo.teams[row.Team];
    const conferenceMatch = conferenceFilter === 'All' || teamInfoEntry?.conference === conferenceFilter;
    const divisionMatch = divisionFilter === 'All' || teamInfoEntry?.division === divisionFilter;
    return conferenceMatch && divisionMatch;
  });

  // Prepare data for the chart, ensuring week is distinct
  const chartData = filteredWeeklyData.reduce((acc, row) => {
    const { week, Team, 'Power Rating': rating } = row;

    let weekEntry = acc.find(entry => entry.week === week);
    if (!weekEntry) {
      weekEntry = { week };
      acc.push(weekEntry);
    }

    weekEntry[Team] = rating;
    return acc;
  }, []);

 // Find the last data point for each team
 const getLastDataPoint = (team) => {
	const teamData = weeklyData.filter(data => data.Team === team);
	if (teamData.length > 0) {
	  return teamData[teamData.length - 1]; // Return the last data point for the team
	}
	return null;
  };
 

  const renderRankChange = (rankChange) => {
	if (rankChange > 0) {
	  return <span className="rank-change" style={{ color: 'red' }}>▼ {rankChange}</span>;
	} else if (rankChange < 0) {
	  return <span className="rank-change" style={{ color: 'green' }}>▲ {Math.abs(rankChange)}</span>;
	} else {
	  return <span className="rank-change" style={{ color: 'black', padding: '2px' }}>—</span>;
	}
  };
 

  // Render filters
  const renderFilters = () => (
    <div className="filters">
      <label>
        Conference:
        <select value={conferenceFilter} onChange={(e) => setConferenceFilter(e.target.value)}>
          <option value="All">All</option>
          <option value="Eastern">Eastern</option>
          <option value="Western">Western</option>
        </select>
      </label>
      <label>
        Division:
        <select value={divisionFilter} onChange={(e) => setDivisionFilter(e.target.value)}>
          <option value="All">All</option>
          <option value="Atlantic">Atlantic</option>
          <option value="Metropolitan">Metropolitan</option>
          <option value="Central">Central</option>
          <option value="Pacific">Pacific</option>
        </select>
      </label>
    </div>
  );

  return (
    <div>
      <div className='header-container'>
        <button onClick={handleToggle}>
          {showGrid ? 'Show Timeline' : 'Show Grid'}
        </button>
        <h1>Current Weekly Rating</h1>
        {renderFilters()}
      </div>

      {showGrid ? (
        <div className="grid-container">
          {filteredRankings.map((team, index) => {
            const teamColor = teamColors[team.Team] || '#FFFFFF';
            const rankChange = team['Rank Change'];

            return (
              <div
                key={index}
                className="grid-item"
                style={{ border: `3px solid ${teamColor}` }}
              >
			    <Link to={`/team/${team.Team}`} style={{ color: teamColor, textDecoration: 'none' }}>
                  <h3 style={{ color: teamColor }}>{team.Team}</h3>
                  <p style={{ color: teamColor }}>Rank: {team['Rank']}</p>
                  <p style={{ color: teamColor }}>Power Rating: {team['Power Rating']}</p>
                  <p style={{ color: teamColor }}>Weekly Change: {renderRankChange(rankChange)}</p>
				</Link>
              </div>
            );
          })}
        </div>
      ) : (
        <ResponsiveContainer width="90%" height={550}>
          <LineChart data={chartData}>
            <XAxis 
              dataKey="week" 
              type="number" 
              domain={['dataMin', 'dataMax']} 
              tickFormatter={(tick) => Number.isInteger(tick) ? tick : ''} 
              label={{ value: 'Week', position: 'insideBottom', offset: -5, style: { fontSize: '24px', fill: '#00FFFF' } }}
            />
            <YAxis   
              domain={[0, 102]}  // Set the domain for the Y-axis from 0 to 100
              ticks={[0, 25, 50, 75, 100]}
            />
            {Object.keys(teamColors).map((team, index) => {
              const lastDataPoint = getLastDataPoint(team);
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={team}
                  stroke={teamColors[team]}
                  dot={true}
                  name={team}
                >
                  {lastDataPoint && (
                    <LabelList 
                      dataKey={team}
                      position="right"
                      formatter={() => team}
                      fill={teamColors[team]}
                    />
                  )}
                </Line>
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
	<div className='ratings-explanation'>
		<p>Power Ratings and Power Rankings are two very different ideas. Power Ratings are a value assigned to teams based off their statistical body of work through a season.
			Power Rankings are a much more reactive and opinion based measure based off who has beaten who and how teams are performing in a more recent span of time.
		</p>
	</div>
    </div>
  );
};

export default TeamRankingsGrid;
