// src/components/CapHitPredictor.js
import React from 'react';
import './CapHitPredictor.css';
import { Link } from 'react-router-dom'; // Import Link for React Router

function CapHitPredictor() {
  return (
    <div className="cap-hit-predictor">
      <h2>Cap Hit Predictor</h2>
	  <div className="model-notes">
        <Link to="/model-notes">Model Notes</Link>
      </div>
      <iframe
        src="https://thehockeyscience.shinyapps.io/cap-hit-predictor/"
        title="Cap Hit Predictor"
        width="100%"
        height="675px" // Adjust height as needed
        style={{ border: 'none' }}
      ></iframe>
	    <div className="credits">
        Data sourced from <a href="https://moneypuck.com" target="_blank" rel="noopener noreferrer">MoneyPuck</a> and <a href="https://capfriendly.com" target="_blank" rel="noopener noreferrer">CapFriendly</a> (pre-shutdown). Data sourced from 2023-2024 season.
      </div>
    </div>
  );
}

export default CapHitPredictor;

